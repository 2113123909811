"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { utils, Contract, ethers, providers } from "ethers";
var pachacuyInfoAddress = __pachacuyInfoAddress__;
var rpcUrl = __rpcUrl__;
import pachacuyInformationAbi from "./abi/pachacuyInfoAbi";
var provider = new providers.JsonRpcProvider(rpcUrl);
var pachacuyInformationContract = new Contract(pachacuyInfoAddress, pachacuyInformationAbi, provider);
////////////////////////
///       INIT       ///
////////////////////////
/**
 * @dev This function inits the library and connects to the blockchain
 * @return Gives back the contract PachacuyInformation
 */
export function getContract() {
    return pachacuyInformationContract;
}
export function getAllGameInformation() {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, pachacuyInformationContract.getAllGameInformation()];
                case 1: return [2 /*return*/, _a.sent()];
            }
        });
    });
}
export function getInformationByRank(_rank) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, pachacuyInformationContract.getInformationByRank(_rank)];
                case 1: return [2 /*return*/, _a.sent()];
            }
        });
    });
}
////////////////////////
///  EXCHANGE RATE   ///
////////////////////////
export function getExchangeRatePcuyToSami() {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, pachacuyInformationContract.getExchangeRatePcuyToSami()];
                case 1: return [2 /*return*/, _a.sent()];
            }
        });
    });
}
export function convertBusdToPcuy(_busdAmount) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, pachacuyInformationContract.convertBusdToPcuy(_busdAmount)];
                case 1: return [2 /*return*/, _a.sent()];
            }
        });
    });
}
export function convertPcuyToSami(_pcuyAmount) {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, pachacuyInformationContract.convertPcuyToSami(_pcuyAmount)];
                case 1: return [2 /*return*/, _a.sent()];
            }
        });
    });
}
export function convertSamiToPcuy(_samiAmount) {
    return __awaiter(this, void 0, void 0, function () {
        var _a, _b;
        return __generator(this, function (_c) {
            switch (_c.label) {
                case 0:
                    _b = (_a = utils).formatEther;
                    return [4 /*yield*/, pachacuyInformationContract.convertSamiToPcuy(_samiAmount)];
                case 1: return [2 /*return*/, _b.apply(_a, [_c.sent()])];
            }
        });
    });
}
export function exchangeRateBusdToPcuy() {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, pachacuyInformationContract.exchangeRateBusdToPcuy()];
                case 1: return [2 /*return*/, _a.sent()];
            }
        });
    });
}
export function exchangeRatePcuyToSami() {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, pachacuyInformationContract.exchangeRatePcuyToSami()];
                case 1: return [2 /*return*/, _a.sent()];
            }
        });
    });
}
export function chakraPrice() {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, pachacuyInformationContract.chakraPrice()];
                case 1: return [2 /*return*/, _a.sent()];
            }
        });
    });
}
export function misayWasiPrice() {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, pachacuyInformationContract.misayWasiPrice()];
                case 1: return [2 /*return*/, _a.sent()];
            }
        });
    });
}
export function pachaPrice() {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, pachacuyInformationContract.pachaPrice()];
                case 1: return [2 /*return*/, _a.sent()];
            }
        });
    });
}
export function qhatuWasiPrice() {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, pachacuyInformationContract.qhatuWasiPrice()];
                case 1: return [2 /*return*/, _a.sent()];
            }
        });
    });
}
/**
 * @notice Calculates the price of businesses and returns it in PCUY tokens
 * @dev Allow businesses "CHAKRA", "PACHA", "QHATU_WASI", "MISAY_WASI", "GUINEA_PIG_1", "GUINEA_PIG_2", "GUINEA_PIG_3"
 * @param key Describe a type of business (CHAKRA, PACHA, ...)
 * @return Its price in PCUY tokens
 */
export function getPriceInPcuy(key) {
    return __awaiter(this, void 0, void 0, function () {
        var businesses;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    businesses = [
                        "CHAKRA",
                        "PACHA",
                        "QHATU_WASI",
                        "MISAY_WASI",
                        "GUINEA_PIG_1",
                        "GUINEA_PIG_2",
                        "GUINEA_PIG_3",
                    ];
                    if (!businesses.includes(key))
                        throw new Error("Incorrect business");
                    return [4 /*yield*/, pachacuyInformationContract.getPriceInPcuy(ethers.utils.toUtf8Bytes(key))];
                case 1: return [2 /*return*/, _a.sent()];
            }
        });
    });
}
